// TODO: Separate calls into separate folders

import {
    HttpGet,
    HttpPost,
    HttpPut,
    HttpDelete,
    CancelToken,
    HttpPatch,
} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from './Settings';
import { mapEntityTypeToCollectionType } from './Constants';

export { CancelToken };

export async function postNewCompanyContact(
    companyId,
    contactId,
    contactRole,
    influence,
    functionTitle,
    notifyMessage
) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/persons/${contactId}`, {
        roleId: contactRole,
        decisionMakerUnit: influence,
        functionTitle: functionTitle,
    }).then((response) => {
        notifyCompanyAdd(companyId, notifyMessage);
        return response;
    });
}
export async function deleteTask(id, companyId) {
    return HttpDelete(`${Settings.apiUrls.cci}/teams/${companyId}/tasks/${id}`);
}
export async function deleteContact(companyId, contactId) {
    return HttpDelete(`${Settings.apiUrls.cci}/companies/${companyId}/persons/${contactId}`);
}
export async function putTask(id, companyId, data) {
    return HttpPut(`${Settings.apiUrls.cci}/companies/${companyId}/tasks/${id}`, data);
}
export async function patchCompanyContact(companyId, contactId, patches) {
    return HttpPatch(
        `${Settings.apiUrls.cci}/companies/${companyId}/persons/${contactId}`,
        patches
    );
}

export async function getContact(id, cache, cancelToken) {
    const url = `${Settings.apiUrls.cci}/persons/${id}`;
    if (cache && cache[url]) return Promise.resolve(() => cache[url]);
    else
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
}

export function getContactIconUrl(contactId) {
    return `${Settings.apiUrls.cci}/proxy/persons/${contactId}/icon`;
}

export function getCompanyIconUrl(companyId) {
    return `${Settings.apiUrls.cci}/proxy/companies/${companyId}/icon`;
}

let source = null;
export async function getContacts(input, companyId, cache) {
    if (source !== null) source.cancel();
    let url = `${Settings.apiUrls.cci}/persons/details/search?take=10&skip=0&term=${input}`;
    if (companyId) {
        url += `&excludeCompany=${companyId}`;
    }
    if (cache && cache[url]) {
        return cache[url];
    } else {
        source = CancelToken.source();
        return HttpGet(url, source.token)
            .then((response) => {
                cache[url] = response.result;
                return response.result;
            })
            .catch((error) => {
                // Canceled
                if (error.code === 499) return;
            });
    }
}

let getCompanyContactsSource = null;
export async function getCompanyContacts(input) {
    if (getCompanyContactsSource !== null) getCompanyContactsSource.cancel();
    let url = `${Settings.apiUrls.cci}/company-persons/details/search?take=10&skip=0&term=${input}`;
    getCompanyContactsSource = CancelToken.source();
    return HttpGet(url, getCompanyContactsSource.token)
        .then((response) => response.result)
        .catch((error) => {
            // Canceled
            if (error.code === 499) return;
        });
}

let getContactsFromCompanySource = null;
export async function getContactsFromCompany(companyId) {
    if (getContactsFromCompanySource !== null) getContactsFromCompanySource.cancel();
    getContactsFromCompanySource = CancelToken.source();
    return HttpGet(
        `${Settings.apiUrls.cci}/companies/${companyId}/persons`,
        getContactsFromCompanySource.token
    );
}

function notifyCompanyAdd(id, message) {
    const notification = {
        entityId: id,
        action: 0,
        message: message,
    };
    // Just send it and then ignore it. Nothing happens when it completes and nothing happens when it fails
    HttpPost(`${Settings.apiUrls.cci}/companies/notifications`, notification);
}

export async function doesMaconomyIdExist(maconomyId, cancelToken) {
    return HttpGet(`${Settings.apiUrls.cci}/companies/maconomy/${maconomyId}/exists`, cancelToken);
}

export async function patchCompany(companyId, patches, cancelToken) {
    return HttpPatch(`${Settings.apiUrls.cci}/companies/${companyId}`, patches, cancelToken);
}

export async function postFileToFileService(file) {
    return HttpPost(`${Settings.apiUrls.fileService}/files`, file);
}

export async function postFileToCompany(id, file) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${id}/documents`, file);
}

export async function getCity(id) {
    return HttpGet(`${Settings.apiUrls.globalConfig}/cities/${id}`);
}

let searchForCitySource = null;
export async function searchForCity(regionId, countryId, term, skip = 0, take = 20, cache) {
    if (searchForCitySource) searchForCitySource.cancel();

    searchForCitySource = CancelToken.source();
    const queryParams = [];
    //if (regionId) queryParams.push(`region=${regionId}`);
    //if (countryId) queryParams.push(`country=${countryId}`);
    if (term.lenght !== 0) queryParams.push(`term=${term}`);
    //queryParams.push(`skip=${skip}`);
    //queryParams.push(`take=${take}`);

    const url = `${Settings.apiUrls.globalConfig}/cities/country/${countryId}?${queryParams}`;

    //if (cache && cache[url]) return cache[url];
    console.log(term);
    return HttpGet(
        url,
        // {
        //     skip: skip,
        //     take: take,
        //     filters: [
        //         {
        //             type: 'country',
        //             parameters: [{ name: 'id', value: countryId }],
        //         },
        //         {
        //             type: 'name',
        //             parameters: [{ name: 'term', value: term ? term : null }],
        //         },
        //     ],
        // },
        searchForCitySource?.token
    ).then((response) => {
        //if (cache) cache[url] = response.cities;
        return response;
    });
}

export async function getSource(guid, cancelToken = null, setMessage) {
    const url = `${Settings.apiUrls.scansources}/scansources/${guid}`;
    return HttpGet(url, cancelToken);
}

export async function sendLog(error, additionalInfo, userId) {
    HttpPost(`${Settings.apiUrls.logService}/log/client-error`, {
        applicationId: Settings.appId,
        error: JSON.stringify(error),
        errorInfo: additionalInfo,
        url: window.location.href,
        userId: userId,
    }).catch((error) => Promise.resolve());
}

export async function getCountryCorruption(countryId) {
    return HttpGet(`${Settings.apiUrls.globalConfig}/data/countries/${countryId}/corruption-info`);
}

export async function getCompanyDocuments(companyId) {
    const url = `${Settings.apiUrls.cci}/companies/${companyId}/documents`;
    return HttpGet(url);
}

let getTeamsSource = null;
export async function getTeams(input, onError, onTimeout, onUnknownError) {
    if (getTeamsSource !== null) getTeamsSource.cancel();
    getTeamsSource = CancelToken.source();
    const url = `${Settings.apiUrls.cci}/teams/details/search?take=10&skip=0&term=${input}`;
    return HttpGet(url, getTeamsSource.token)
        .then((response) => response.result)
        .catch((error) => {
            switch (error.code) {
                case 499:
                    break;
                case 403:
                    onError(`You have no permission to search for teams`);
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    onError('timeout', error, url);
                    break;
                default:
                    onError('unknown', error, url);
                    break;
            }
        });
}

export async function postNewLocation(id, type) {
    const collectionType = mapEntityTypeToCollectionType(type);
    return HttpPatch(`${Settings.apiUrls.cci}/${collectionType}/${id}`, [
        { op: 'add', path: 'locations/-', value: {} },
    ]);
}

export async function getCompany(id, cancelToken = null) {
    return HttpGet(`${Settings.apiUrls.cci}/companies/${id}`, cancelToken);
}

let getCompaniesSource = null;
export async function getCompanies(input, personId = null, cache) {
    if (getCompaniesSource !== null) getCompaniesSource.cancel();
    getCompaniesSource = CancelToken.source();

    let url = `${Settings.apiUrls.cci}/companies/details/search?take=10&skip=0&term=${input}`;
    if (personId) url += `&excludePerson=${personId}`;
    if (cache && cache[url]) {
        return cache[url];
    } else {
        return HttpGet(url, getCompaniesSource.token)
            .then((response) => {
                if (cache) cache[url] = response.result;
                return response.result;
            })
            .catch((error) => {
                // Canceled
                if (error.code === 499) return;
            });
    }
}

export async function getCompanyContact(id, cache, cancelToken) {
    const url = `${Settings.apiUrls.cci}/company-persons/${id}`;
    if (cache && cache[url]) return Promise.resolve(() => cache[url]);
    else
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
}

export async function postActivity(companyId, dataObject) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/activities`, dataObject);
}

export async function getTeam(teamId, onError, cancelToken = null, cache) {
    const url = `${Settings.apiUrls.cci}/teams/${teamId}`;
    if (cache && cache[url]) return cache[url];
    else {
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
    }
}

export async function getDeltaresUserDetails(deltaresId, cancelToken, cache) {
    const url = `${Settings.apiUrls.singleSignOn}/users/${deltaresId}/details`;

    if (cache?.[url]) return cache[url];
    else if (cache) {
        cache[url] = await getUserDetails(url, cancelToken, cache);
        return cache[url];
    } else getUserDetails(url, cancelToken);
}

function getUserDetails(url, cancelToken, cache = null) {
    return HttpGet(url, cancelToken)
        .then((response) => {
            if (cache) cache[url] = response;
            return response;
        })
        .catch((error) => {
            switch (error.code) {
                case 499:
                    // Canceled request
                    break;
                case 400:
                    sendLog(JSON.stringify(error), `Bad request when fetching "${url}"`);
                    break;
                case 500:
                    sendLog(
                        JSON.stringify(error),
                        `Internal server error while retrieving "${url}"`
                    );
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    sendLog(JSON.stringify(error), `Connection timeout while fetching "${url}"`);
                    break;
                default:
                    sendLog(
                        JSON.stringify(error),
                        `An unknown error occurred while fetching "${url}"`
                    );
                    break;
            }
        });
}

export async function addCompanyRelation(companyId, relationId, type, note) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/relations/${relationId}`, {
        relationType: type,
        notes: note,
    });
}

export async function patchRelation(relationId, patches) {
    return HttpPatch(`${Settings.apiUrls.cci}/companies/relations/${relationId}`, patches);
}

export async function removeCompanyRelation(relationId) {
    return HttpDelete(`${Settings.apiUrls.cci}/companies/relations/${relationId}`);
}

export function getLatestCompanyActivities(companyId, cancelToken) {
    let url = `${Settings.apiUrls.cci}/companies/${companyId}/latest-activities`;
    return HttpGet(url, cancelToken);
}

export async function updatePermissionMode(mode, id, type) {
    return HttpPut(`${Settings.apiUrls.cci}/${type}/${id}/permission-mode`, {
        mode: mode,
    });
}

export async function getPermissionForPersonOnEntity(entityId, type, userId) {
    return HttpGet(
        `${Settings.apiUrls.cci}/${mapEntityTypeToCollectionType(
            type
        )}/${entityId}/permissions/${userId}`
    );
}

export async function changePermissionForPersonOnEntity(entityId, type, userId, role) {
    return HttpPut(
        `${Settings.apiUrls.cci}/${mapEntityTypeToCollectionType(type)}/${entityId}/permissions`,
        {
            userId: userId,
            permissionRole: role,
            specialPermissions: [],
        }
    );
}

export async function getConversionToEuro(currency, date, amount, cancelToken) {
    return HttpGet(
        `${Settings.apiUrls.globalConfig}/data/currencies/conversion-info?target=EUR&source=${currency}&conversionDate=${date}`,
        cancelToken
    );
}

export const getProjectById = async (projectId) => {
    return await HttpGet(`${Settings.apiUrls.projects}/projects/${projectId}`);
  };

export async function getCompanyById(companyId) {
    return HttpGet(`${Settings.apiUrls.cci}/companies/${companyId}`);
}
export async function isAdmin() {
    return HttpGet(`${Settings.apiUrls.globalConfig}/permissions/access`);
}