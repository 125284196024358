import React from 'react';

import Icon from 'ibis-design-system/lib/components/core/Icon';

export const DEFAULT_COLLECTION = 'following';
export const DEFAULT_COLLECTION_TAB = 'activity';

export const COORDINATOR = 3;
export const CONTACT_PERSON = 2;
export const EXPERT = 1;

export const RESPONSIBLE_PERSON = 1;

export const CORRUPTION_INFO_SITE = 'https://www.transparency.org/country';

export const CompanyRelationTypes = [
    { id: 'Adversary', label: 'Adversary' },
    { id: 'Competitor', label: 'Competitor' },
    { id: 'Parent', label: 'Parent' },
    { id: 'Partner', label: 'Partner' },
    { id: 'Other', label: 'Other' }
];

export const GENDERS = [
    { key: 'NotSet', text: 'No gender set' },
    { key: 'Male', text: 'Male' },
    { key: 'Female', text: 'Female' }
];

export const PERMISSIONS = [
    { key: 'NoAccess', label: 'No access', description: 'Can not view' },
    {
        key: 'Observer',
        label: 'Observer',
        description: 'Can only view'
    },
    { key: 'Contributor', label: 'Contributor', description: 'Can add messages and documents' },
    {
        key: 'Editor',
        label: 'Editor',
        description: 'Can edit all information'
    },
    {
        key: 'Owner',
        label: 'Owner',
        description: 'No limitations, can archive'
    }
];

export const AUTHORITIES = [
    { id: 'NotSet', label: 'No authority set' },
    {
        id: 'Influencer',
        label: 'Influencer'
    },
    {
        id: 'Initiator',
        label: 'Initiator'
    },
    {
        id: 'GateKeeper',
        label: 'Gate keeper'
    },
    {
        id: 'Buyer',
        label: 'Buyer'
    },
    {
        id: 'DecisionMaker',
        label: 'Decision maker'
    },
    { id: 'User', label: 'User' }
];

export const ACTIVITY_TYPES = [
    {
        id: 'Action',
        label: 'Action',
        icon: <Icon name='WaitlistConfirm' style={{ position: 'relative', left: '10px', top: '10px' }} />
    },
    {
        id: 'Update',
        label: 'Update',
        icon: <Icon name='EventToDoLogo' style={{ position: 'relative', left: '10px', top: '10px' }} />
    },
    {
        id: 'Note',
        label: 'Note',
        icon: <Icon name='EditNote' style={{ position: 'relative', left: '10px', top: '10px' }} />
    },
];

export function mapEntityTypeToCollectionType(type) {
    switch (type) {
        case 'company':
            return 'companies';
        case 'person':
            return 'persons';
        case 'team':
            return 'teams';
        default:
            return null;
    }
}

export function getCollectionTabs(id) {
    switch (id) {
        case 'companies':
            return ['Activity', 'Regions', 'Recent'];
        case 'persons':
            return ['Activity', 'Alphabetical', 'Recent'];
        case 'teams':
            return ['Activity', 'Alphabetical', 'Recent'];
        default:
            return ['Activity', 'Regions', 'Alphabetical', 'Recent'];
    }
}

export const teamTypes = [
    { id: 'Country', label: 'Country' },
    { id: 'Region', label: 'Region' },
    { id: 'Account', label: 'Account' },
    { id: 'Nexus', label: 'Nexus' }
];

export const labelTypeOptions = [
    { id: 'NotSet', label: 'No label set' },
    { id: 'Work', label: 'Work' },
    { id: 'Private', label: 'Private' },
    { id: 'Other', label: 'Other' }
];

export const numberTypeOptions = [
    { id: 'Email', label: 'Email' },
    { id: 'Website', label: 'Website' },
    { id: 'Phone', label: 'Phone' },
    { id: 'Mobile', label: 'Mobile' },
    { id: 'Fax', label: 'Fax' }
];

export const mixedSearchCollections = ['following', 'archive'];

export const PUBLIC_PERMISSION_MODE = 'Public';
export const SHARED_PERMISSION_MODE = 'Shared';
export const PRIVATE_PERMISSION_MODE = 'Private';

export const getStateIcon = state =>
    ({
        [PUBLIC_PERMISSION_MODE]: 'Globe',
        [SHARED_PERMISSION_MODE]: 'ReminderGroup',
        [PRIVATE_PERMISSION_MODE]: 'ReminderPerson'
    }[state]);

export const TIMES = [
    { key: '8:00', text: '8:00' },
    { key: '8:30', text: '8:30' },
    { key: '9:00', text: '9:00' },
    { key: '9:30', text: '9:30' },
    { key: '10:00', text: '10:00' },
    { key: '10:30', text: '10:30' },
    { key: '11:00', text: '11:00' },
    { key: '11:30', text: '11:30' },
    { key: '12:00', text: '12:00' },
    { key: '12:30', text: '12:30' },
    { key: '13:00', text: '13:00' },
    { key: '13:30', text: '13:30' },
    { key: '14:00', text: '14:00' },
    { key: '14:30', text: '14:30' },
    { key: '15:00', text: '15:00' },
    { key: '15:30', text: '15:30' },
    { key: '16:00', text: '16:00' },
    { key: '16:30', text: '16:30' },
    { key: '17:00', text: '17:00' },
    { key: '17:30', text: '17:30' }
];
